import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/jwtXW2Bc6tUEEYtcnIIt/locations/MvLV4OUJjaddWhfyWwXM'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView column center>
          <CFView column center>
            <CFImage w="70%" src={logo} alt="Mill Bay Sushi Logo" mt="15px" />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView row justifyStart h="70px" bg="transparent" zIndex={98}>
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="30px"
          >
            <CFView row justifyStart mt="75px">
              <CFImage
                h="100px"
                mt="5px"
                src={logo}
                alt="Mill Bay Sushi Logo"
                zIndex={98}
              />
              {/* <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
                <CFView mt="15px" zIndex={99}>
                  <LocationSelect />
                </CFView>
              </CFSelect> */}
            </CFView>
            {/* <CFView hover mt="12px">
              <CFLink href={orderingUrl}>
                <CFImage
                  h="60px"
                  src={viewMenu}
                  alt="Mill Bay Sushi View Menu Button"
                />
              </CFLink>
            </CFView> */}
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
