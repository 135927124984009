import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, menu, mobileHero, viewMenu } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95VH"
          w="100%"
          image={`url(${mobileHero}) center/ cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView mb="20px">
            <CFView pulsate mb="10px">
              <OrderPickupButton />
            </CFView>
            {/* <OrderDeliveryButton /> */}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) left / cover no-repeat`}
          zIndex={90}
          column
          alignStart
          justifyCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          pl="50px"
        >
          <CFImage src={heroText} w="380px" alt="About" />
          <CFView pulsate mt="30px">
            <OrderPickupButton />
          </CFView>
          {/* <CFView mt="15px">
            <OrderDeliveryButton />
          </CFView> */}
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
